import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Text, Image } from "../components/Core"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Contact from "../components/Services/contact"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import Button from "../components/Buttons/Button"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import MobileCarousel from "../components/Carousels/MobileCarousel"

function SingleOurWork({ data }) {
  let post = data.allOurWorkJson.nodes[0]
  const {
    hero,
    aboutClient,
    branding,
    website,
    videoProduction,
    podcast,
    photoGallery
  } = post
  const ourWorkPages = data.ourWork.nodes

  let clientsSliderRef = useRef(null)
  const clientsSliderSettings = {
    className: "center",
    infinite: true,
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    dots: false,
    centerPadding: "0",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerPadding: "20px",
          centerMode: true
        }
      }
    ]
  }

  return (
    <Layout
      navSpacer
      theme="dark-teal"
      language="en"
      className="single-our-work sow">
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section>
        <Container>
          <div className="sow__heading">
            <div className="sow__heading-content">
              <Text
                as="p"
                text={hero.subheading}
                className="sow__heading-category"
              />
              <Text as="h1" text={hero.heading} className="mb-2" />
              <div className="is-hidden-desktop sow__heading-img">
                <Image
                  useAR={false}
                  publicId={hero.heroImageMobile}
                  wrapperClassName="mb-1 mt-1"
                />
              </div>
              <Text as="p" text={hero.blurb} className="mt-1" />
            </div>
            <div className="sow__heading-img is-hidden-touch">
              <Image useAR={false} publicId={hero.heroImage} />
            </div>
          </div>
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <div className="sow__client-heading">
            <div className="sow__client-heading-img">
              <Image
                publicId={aboutClient.desktopImage}
                wrapperClassName="is-hidden-touch"
              />
              <Image
                publicId={aboutClient.mobileImage}
                wrapperClassName="is-hidden-desktop"
              />
            </div>
            <div className="sow__client-heading-text">
              <Text as="h2" text={aboutClient.heading} />
              <Text as="p" text={aboutClient.blurb} className="mt-1" />
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${aboutClient.youtube}`}
                language={"en"}
                buttonClass="contained sow__button-hover mb-2"
              />
            </div>
          </div>
        </Container>
      </Section>

      <Section zeroTop>
        <Container>
          <div className="sow__client-content">
            <Text text={aboutClient.content} />
          </div>
        </Container>
      </Section>

      {branding.hasThisSection && (
        <>
          <Section className="is-hidden-touch">
            <Container>
              <div className="sow__branding">
                <Text as="h3" text={branding.heading} />
                <div className="sow__branding-sctn-1">
                  <div>
                    <Image
                      useAR={false}
                      publicId={branding.imageList[0].image}
                    />
                    <div className="sow__branding-img-sctn mt-2">
                      <Image
                        publicId={branding.imageList[1].image}
                        useAR={false}
                      />
                      <Image
                        publicId={branding.imageList[2].image}
                        useAR={false}
                      />
                    </div>
                    <div className="sow__branding-arrow mt-1">
                      <UpRightArrow />
                    </div>
                  </div>
                  <div>
                    <Text text={branding.textSection[0].text} />
                  </div>
                </div>
                <div className="sow__branding-sctn-2">
                  <Text text={branding.textSection[1].text} />
                  <Image publicId={branding.imageList[3].image} useAR={false} />
                </div>
              </div>
            </Container>
          </Section>
          <Section className="is-hidden-desktop">
            <Container>
              <Text as="h3" text={branding.heading} className="mb-2" />
            </Container>
            <div className="sow__branding-slider">
              <MobileCarousel>
                {branding.mobileImageList.map((item, index) => (
                  <div key={index}>
                    <Image publicId={item.image} useAR={false} />
                  </div>
                ))}
              </MobileCarousel>
            </div>
            <Container>
              {branding.textSection.map((item, index) => (
                <Text text={item.text} key={index} className="mt-1" />
              ))}
            </Container>
          </Section>
        </>
      )}

      {website.hasThisSection && (
        <Section>
          <Container>
            <div className="sow__website">
              <Text as="h3" text={website.heading} className="mb-2" />
              {website.imageList && (
                <Image publicId={website.imageList[0].image} />
              )}
              <div className="sow__website-content">
                <div className="sow__website-img-list">
                  {website.imageList &&
                    website.imageList.map(
                      (item, index) =>
                        index !== 0 && (
                          <div key={index}>
                            <Image
                              width="590"
                              height="331"
                              publicId={item.image}
                            />
                          </div>
                        )
                    )}
                </div>
                <Text text={website.content} />
              </div>
            </div>
          </Container>
        </Section>
      )}

      {videoProduction.hasThisSection && (
        <Section className="sow__vp">
          <Container>
            <div>
              <Text as="h3" text={videoProduction.heading} className="mb-1" />
              <Text text={videoProduction.content} />
            </div>
          </Container>
        </Section>
      )}

      {photoGallery.length > 0 && (
        <Section>
          <Container className="no-padding-touch">
            <div>
              <Text
                as="h3"
                text="Photography"
                className="sow__gallery-heading"
              />
              <div className="sow__gallery">
                <button
                  aria-label="previous carousel slide"
                  className={`reset slider-arrow is-hidden-touch`}
                  onClick={() => clientsSliderRef.slickPrev()}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <div className="sow__gallery-slider">
                  <Slider
                    ref={slider => {
                      clientsSliderRef = slider
                    }}
                    {...clientsSliderSettings}>
                    {photoGallery.map((item, index) => (
                      <div key={index}>
                        <Image publicId={item.image} useAR={false} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <button
                  aria-label="next carousel slide"
                  className={`reset slider-arrow is-hidden-touch`}
                  onClick={() => clientsSliderRef.slickNext()}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
              <div className="sow__gallery-arrow-wrapper is-hidden-desktop">
                <button
                  aria-label="previous carousel slide"
                  className={`reset slider-arrow`}
                  onClick={() => clientsSliderRef.slickPrev()}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  aria-label="next carousel slide"
                  className={`reset slider-arrow`}
                  onClick={() => clientsSliderRef.slickNext()}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </Container>
        </Section>
      )}

      {podcast.hasThisSection && (
        <Section zeroTopMobile>
          <Container>
            <div className="rounded sow__podcast">
              <div className="sow__podcast-content">
                <Text text={podcast.content} />
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${aboutClient.youtube}`}
                  language={"en"}
                  buttonClass="contained sow__button-hover"
                />
              </div>
              <div className="sow__podcast-image">
                <Image
                  wrapperClassName="is-hidden-touch"
                  publicId={podcast.desktopImage}
                />
                <Image
                  publicId={podcast.mobileImage}
                  wrapperClassName="is-hidden-desktop"
                />
              </div>
            </div>
          </Container>
        </Section>
      )}

      <Section>
        <Container>
          <div className="sow__other-work">
            <div className="sow__other-work-title-card">
              <Text
                as="h3"
                text="Transforming Businesses, <br>Driving Results"
              />
              <Image
                publicId={"NUVO/V4/success-metrics-nuvo-success.png"}
                useAR={false}
              />
            </div>
            {ourWorkPages.map((item, index) => (
              <div className="rounded sow__other-work-item" key={index}>
                <Image
                  wrapperClassName="is-hidden-touch"
                  publicId={item.query.ourWorkSingleImages[0]}
                />
                <Image
                  publicId={item.query.ourWorkSingleImages[1]}
                  wrapperClassName="is-hidden-desktop"
                />
                <div className="sow__other-work-content">
                  <Text as="h4" text={item.category} />
                  <Button
                    buttonText="Learn More"
                    href={`/${item.title}`}
                    contained
                    className="sow__button-hover mt-1"
                  />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <Contact className="dark-theme" arrow={false} />
        </Container>
      </Section>
    </Layout>
  )
}

export default SingleOurWork

export const pageQuery = graphql`
  query singleWorkQuery($title: String!) {
    allOurWorkJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        hero {
          heading
          subheading
          blurb
          heroImage
          heroImageMobile
        }
        aboutClient {
          heading
          blurb
          desktopImage
          mobileImage
          content
          youtube
        }
        branding {
          hasThisSection
          heading
          imageList {
            image
          }
          mobileImageList {
            image
          }
          textSection {
            text
          }
        }
        website {
          hasThisSection
          heading
          content
          imageList {
            image
          }
        }
        videoProduction {
          hasThisSection
          heading
          blurb
          content
        }
        podcast {
          content
          desktopImage
          mobileImage
          youtube
          hasThisSection
        }
        photoGallery {
          image
        }
      }
    }
    ourWork: allOurWorkJson(
      sort: { order: ASC, fields: order }
      filter: { title: { ne: $title } }
      limit: 2
    ) {
      nodes {
        title
        category
        query {
          ourWorkSingleImages
        }
      }
    }
  }
`
